// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'jquery/dist/jquery.min'
import {popper} from "@popperjs/core";
import "@popperjs/core"
import "@fortawesome/fontawesome-free/js/all"

import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/js/bootstrap.bundle"
import "bootstrap/dist/css/bootstrap.min.css"
import "./dashboard"

import "../../assets/stylesheets/application.scss"


// Import the specific modules you may need (Modal, Alert, etc)

// The stylesheet location we created earlier
// import "../../assets/stylesheetheets/application"

// If you're using Turbolinks. Otherwise simply use: jQuery(function () {
$(document).on("turbolinks:load", () => {
    // Both of these are from the Bootstrap 5 docs
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })
})
// var jQuery = require('jquery')
//
// global.$ = global.jQuery = jQuery;
// window.$ = window.jQuery = jQuery;
Rails.start()
// Turbolinks.start()
// ActiveStorage.start()
